<template>
  <div class="vca-column">
    <vca-accordion :header="$t('faqs.general.vca.header')">
      <div slot="body" v-html="$t('faqs.general.vca.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.age.header')">
      <div slot="body" v-html="$t('faqs.general.age.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.pool.header')">
      <div slot="body" v-html="$t('faqs.general.pool.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.data.header')">
      <div slot="body" v-html="$t('faqs.general.data.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.register.header')">
      <div slot="body" v-html="$t('faqs.general.register.body')" />
    </vca-accordion>
  </div>
</template>
<script>
export default {
  name: "FaqsGeneral",
};
</script>
